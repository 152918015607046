import axios from "axios";
import React, { useEffect } from "react";
import { adminURL } from "../app";


const Rulse = () => {
  useEffect(() => {
    axios
      .get(`${adminURL}setting`)
      .then((res) => {
        if (res) console.log("res.data.gameRule", res.data.setting);
        window.location.href = res?.data?.setting?.gameRule;
      })
      .catch((error) => console.log("error", error));
  }, []);
  return;
};

export default Rulse;
